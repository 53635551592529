/*
 |--------------------------------------------------------------------------
 | PhotoSwipe
 | https://github.com/dimsemenov/photoswipe
 |--------------------------------------------------------------------------
 */
var PhotoSwipe = require("photoswipe");
var PhotoSwipeUI_Default = require("./photoswipe-ui-default.js");

(function() {
	var photoswipeInit = function(gallerySelector) {
		var photoswipeOpen = function(index, gallery, disableAnimation, fromURL) {
			var items = photoswipeParseThumbnails(gallery);

			var photoswipeElement = document.querySelectorAll('.pswp')[0];

			var options = {
				index: parseInt(index, 10),
				loop: false,
				pinchToClose: false,
				closeOnScroll: false,
				galleryUID: gallery.getAttribute('data-pswp-uid'),
				mainClass: 'pswp--minimal--dark',
				barsSize: {top: 0, bottom: 0},
				loadingIndicatorDelay: 0,
				captionEl: false,
				fullscreenEl: false,
				zoomEl: false,
				shareEl: false,
				preloaderEl: false,
				tapToToggleControls: false,
				clickToCloseNonZoomable: false,
			};

			if(isNaN(options.index)) {

				return false;
			}

			if(disableAnimation) {
				options.showAnimationDuration = 0;
			}

			photoSwipeGallery = new PhotoSwipe(photoswipeElement, PhotoSwipeUI_Default, items, options);

			photoSwipeGallery.init();

			return true;
		};

		var photoswipeThumbnailClick = function(e) {
			e = e || window.event;

			e.preventDefault ? e.preventDefault() : e.returnValue = false;

			var target = e.target || e.srcElement;

			var clickedListItem = target.closest(".gallery-fullscreen-button");
			var clickedGallery = target.closest(".photoswipe");

			if(!clickedListItem) {

				return false;;
			}

			var index = clickedListItem.getAttribute("data-index");

			if(index < 0) {

				return false;
			}

			photoswipeOpen(index, clickedGallery);

			return true;
		};

		var photoswipeParseThumbnails = function(gallery) {
			var thumbnails = gallery.querySelectorAll(".gallery-fullscreen-button");

			var thumbnail;

			var src;
			var size;
			var title;

			var items = [];

			for(var count = 0; count < thumbnails.length; count++) {
				thumbnail = thumbnails[count];

				if(thumbnail.nodeType !== 1) {

					continue;
				}

				title = thumbnail.getAttribute('data-title');
				src = thumbnail.getAttribute('data-src');
				size = thumbnail.getAttribute('data-size').split('x');
				index = thumbnail.getAttribute('data-index');

				items[index] = {title: title, src: src, w: parseInt(size[0], 10), h: parseInt(size[1], 10)};
			}

			return items;
		};

		var photoswipeParseHash = function(galleries) {
			var hash = window.location.hash.substring(1);

			if(hash.length < 5) {

				return false;;
			}

			var pairs = hash.split('&');

			var parameters = {};

			for(var index = 0; index < pairs.length; index++) {
				if(!pairs[index]) {

					continue;
				}

				var pair = pairs[index].split('=');

				if(pair.length < 2) {

					continue;
				}

				parameters[pair[0]] = pair[1];
			}

			if(parameters.pid && parameters.gid) {
				parameters.gid = parseInt(parameters.gid, 10);

				photoswipeOpen(parameters.pid -1, galleries[parameters.gid -1], true, true);

				return true;
			}

			return false;
		};

		var closest = function closest(el, fn) {

			return el && (fn(el) ? el : closest(el.parentNode, fn));
		};

		var galleries = document.querySelectorAll(gallerySelector);

		for(var index = 0; index < galleries.length; index++) {
			galleries[index].setAttribute('data-pswp-uid', index + 1);
			galleries[index].onclick = photoswipeThumbnailClick;
		}

		photoswipeParseHash(galleries);
	};

	photoswipeInit('.photoswipe');
})();
