/*
 |--------------------------------------------------------------------------
 | First we load all of this project's compatibility JavaScript which
 | includes html5shivm, printshiv, css3-mediaqueries, and Modernizr.
 |--------------------------------------------------------------------------
 */

/*
 |--------------------------------------------------------------------------
 | The HTML5 Shiv
 | https://github.com/aFarkas/html5shiv
 |--------------------------------------------------------------------------
 */
require('./vendor/aFarkas/html5shiv/html5shiv-printshiv');

/*
 |--------------------------------------------------------------------------
 | CSS Helper and CSS3 Media Queries Enabler
 | https://github.com/livingston/css3-mediaqueries-js
 |--------------------------------------------------------------------------
 */
require('./vendor/livingston/css3-mediaqueries/css3-mediaqueries');

/*
 |--------------------------------------------------------------------------
 | Modernizr (a build based on the configurations found in .modernizrrc)
 | https://github.com/Modernizr/Modernizr
 |--------------------------------------------------------------------------
 */
require('Modernizr');

/*
 |--------------------------------------------------------------------------
 | Polyfill Library
 | https://github.com/financial-times/polyfill-library
 |--------------------------------------------------------------------------
 */
//const polyfillLibrary = require('polyfill-library');
//
//const polyfillBundle = polyfillLibrary.getPolyfills({
//	features: {
//		'IntersectionObserver': { }
//	}
//});

/*
 |--------------------------------------------------------------------------
 | Then we will add components to this application and customize
 | the JavaScript scaffolding to fit our unique needs.
 |--------------------------------------------------------------------------
 */

/*
 |--------------------------------------------------------------------------
 | Avoid `console` errors in browsers that lack a console.
 |--------------------------------------------------------------------------
 */
(function() {
	var method;

	var noop = function() { };

	var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
				   'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
				   'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
				   'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];

	var length = methods.length;

	var console = (window.console = window.console || { });

	while(length--) {
		method = methods[length];

		// Only stub undefined methods.
		if(!console[method]) {

			console[method] = noop;
		}
	}
}());

/*
 |--------------------------------------------------------------------------
 | Bootstrap
 | https://github.com/twbs/bootstrap
 |--------------------------------------------------------------------------
 */
import { Alert, Dropdown, Modal } from 'bootstrap';

/*
 |--------------------------------------------------------------------------
 | Workbox (a build based on the configurations found in webpack.mix.js)
 | https://github.com/GoogleChrome/workbox
 |--------------------------------------------------------------------------
 */
import { Workbox } from 'workbox-window';

if('serviceWorker' in navigator) {
	const wb = new Workbox('/service-worker.js');

	wb.register();
}

/*
 |--------------------------------------------------------------------------
 | Yet Another Lazy Loader
 | https://github.com/malchata/yall.js
 |--------------------------------------------------------------------------
 */
var yall = require("yall-js").default;

/*
 |--------------------------------------------------------------------------
 | PhotoSwipe
 | https://github.com/dimsemenov/photoswipe
 |--------------------------------------------------------------------------
 */
require("./vendor/dimsemenov/photoswipe/photoswipe.js");

/*
 |--------------------------------------------------------------------------
 | Bricklayer
 | https://github.com/ademilter/bricklayer
 |--------------------------------------------------------------------------
 */
var Bricklayer = require("./vendor/ademilter/bricklayer/bricklayer.js");

(function() {
	var bricklayers = [];
	var bricklayersElements = document.querySelectorAll('.bricklayer');

	for(var index = 0; index < bricklayersElements.length; index++) {
		bricklayers[index] = new Bricklayer(bricklayersElements[index]);
	}
})();

/*
 |--------------------------------------------------------------------------
 | Flickity, Flickity Fullscreen
 | https://github.com/metafizzy/flickity,
 | https://github.com/metafizzy/flickity-fullscreen
 |--------------------------------------------------------------------------
 */
//require('flickity');
//require('flickity-fullscreen');
//require('flickity-imagesloaded');

/*
 |--------------------------------------------------------------------------
 | Autosize
 | https://github.com/jackmoore/autosize
 |--------------------------------------------------------------------------
 */
var autosize = require("autosize");

/*
 |--------------------------------------------------------------------------
 | Custom Scripts
 |--------------------------------------------------------------------------
 */
function outerHeight(elm_id) {
	var elm = document.getElementById(elm_id);

	if(!elm) {
		return 0;
	}

	var height;

	var margin;

	if(document.all) {
		height = parseInt(elm.currentStyle.height, 10);
		margin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10);
	} else {
		height = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('height'), 10);
		margin = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top'), 10) + parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'), 10);
	}

	return (height + margin);
}

(function() {
	document.querySelector("#navigation-items").addEventListener("hide.bs.collapse", function() {
		document.querySelector("#body").classList.remove("noscroll");
		document.querySelector("#header").classList.remove("expand");
	});

	document.querySelector("#navigation-items").addEventListener("show.bs.collapse", function() {
		document.querySelector("#body").classList.add("noscroll");
		document.querySelector("#header").classList.add("expand");
	});

	document.addEventListener("DOMContentLoaded", function() {
		document.getElementById('content').style.paddingBottom = outerHeight('footer') + "px";

		yall({ noPolyfill: true });
	});

	autosize(document.querySelector('.autosize'));

	window.onresize = function(event) {
		document.getElementById('content').style.paddingBottom = outerHeight('footer') + "px";
	};
})();

/*
 |--------------------------------------------------------------------------
 | ESPA Banner
 |--------------------------------------------------------------------------
 */
(function() {
	const mybody = document.querySelector("body");

	let lang = document.querySelector("html").getAttribute("lang");

	if (!['el', 'en'].includes(lang)) {
		lang = 'en';
	}

	let a = document.createElement("a");

	a.target = "_blank";
	a.href = `/public/documents/espa/afisa-${lang}.pdf`;

	let img = document.createElement("img");

	img.src = `/public/images/espa/banner-${lang}.webp`;
	img.style.right = "0px";
	img.style.bottom = "0px";
	img.style.position = "fixed";
	img.style.maxWidth = "460px";
	img.style.zIndex = "999";
	img.alt = "cosmosfruit.gr - epanek Banner";

	a.appendChild(img);

	mybody.appendChild(a);

	window.acsbanner_position = "bottom";

	window.addEventListener('scroll', function(e) {
		var body = document.body, html = document.documentElement;

		let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

		let scroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop);

		let halfPage = height / 4;

		if ((scroll >= halfPage) && "bottom" == window.acsbanner_position) {
			window.acsbanner_position = "wait-top";

			fadeOut(img);
		}

		if ((scroll < halfPage) && "top" == window.acsbanner_position) {
			window.acsbanner_position = "wait-bottom";

			fadeOut(img);
		}
	});

	const fadeIn = (element) => {
		let fadeEffect = setInterval(function () {
			if (!element.style.opacity) {
				element.style.opacity = 0;
			}

			if (parseFloat(element.style.opacity) < 1) {
				element.style.opacity = parseFloat(element.style.opacity) + 0.1;
			} else {
				clearInterval(fadeEffect);

				setTimeout(() => {
					if ("wait-top" == window.acsbanner_position) {
						window.acsbanner_position = "top" ;
					} else {
						window.acsbanner_position = "bottom" ;
					}
				}, 500)
			}
		}, 20);
	}

	const fadeOut = (element) => {
		let fadeEffect = setInterval(function () {
			if (!element.style.opacity) {
				element.style.opacity = 1;
			}

			element.style.opacity = parseFloat(element.style.opacity)

			if (element.style.opacity > 0) {
				element.style.opacity -= 0.1;
			} else {
				clearInterval(fadeEffect);

				if ("wait-top" == window.acsbanner_position) {
					element.style.bottom = "auto";
					element.style.top = "0";
				} else {
					element.style.top = "auto";
					element.style.bottom = "0";
				}

				setTimeout(() => {
					fadeIn(element)
				}, 500)
			}
		}, 20);
	}

	if (null !== document.querySelector('#g-recaptcha-response')) {
		let label = document.createElement("label");

		label.setAttribute("for", "g-recaptcha-response");
		label.style.fontSize = "0.01px";
		label.innerText = '&nbsp;';

		document.querySelector('#g-recaptcha-response').parentElement.appendChild(label);
	}
})();
